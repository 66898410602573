<template>
  <div class="ip-telephony">
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <bread-crumb></bread-crumb>
    <router-view></router-view>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("Services"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    BreadCrumb,
    HeroBlock,
    FormCommon,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
    };
  },
  methods: {
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image;
        this.icon = response.data.data[0].icon[0].url;
        this.title = response.data.data[0].title;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  mounted() {
    this.getBanner();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.ip-telephony {
  &__descr {
    max-width: 980px;
    padding: 0 15px;
    margin: 0 auto;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 50, 25);
    line-height: calc(25 / 16);
  }

  &__wrap {
    @include property("padding-top", 150, 50);
    @include property("padding-bottom", 150, 50);
    background: linear-gradient(180deg, #1f2a40 0%, #1c1c1c 100%);

    @media (max-width: 500px) {
      padding-right: 20px;
    }
  }
}

.cart {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 767.98px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
